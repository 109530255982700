import { useEffect, useMemo, useReducer, useState } from "react"

import {
  triggerUpload as triggerUploadOperations,
  triggerDMSWatch,
  ExtendedFile,
} from "components/add-documents/DocumentsQueue/DocumentUploadManager/operations"
import reducer, {
  FileDoc,
  INITIAL_STATE,
} from "components/add-documents/DocumentsQueue/DocumentUploadManager/reducer"
import { type Document as TransactionDocument } from "models/Document"

import DocumentUpload from "./DocumentUpload"
import { useTransactionDocuments } from "features/transaction/id/api"
import { useDocument } from "features/document/api"

export { DocumentUpload }

export const DOCUMENT_POLLING_INTERVAL = 3000

type FileUpload = ExtendedFile

type TUseDocumentUpload = {
  transactionId: string
  inQueue?: boolean
  isFromDMS?: boolean
  multiple?: boolean
  pollInterval?: number
  onComplete?: (document: TransactionDocument) => void
  shouldPauseOnComplete?: boolean
}

export function useDocumentUpload({
  transactionId,
  inQueue = true,
  isFromDMS = false,
  multiple = true,
  pollInterval: pollIntervalInput,
  onComplete = () => null,
  shouldPauseOnComplete = false,
}: TUseDocumentUpload): [
  FileDoc[],
  (file: FileUpload) => void,
  () => void,
  (id: string) => void,
] {
  if (!transactionId) throw "Transaction Id required"
  const [isPollingForDocuments, setIsPollingForDocuments] = useState(false)
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const files = useMemo(
    () => state.files.filter((f) => f.status !== "REMOVED"),
    [state.files]
  )

  const countOfInProgressUploads = files.filter(
    (f) =>
      f.status !== "CONVERSION_COMPLETE" &&
      f.status !== "QUEUED" &&
      !f.status.includes("FAILED")
  ).length

  const hasInProgressUploads = !!countOfInProgressUploads
  const areAllDocumentsComplete = files.every(
    (f) => f.status === "CONVERSION_COMPLETE"
  )
  const isFilesEmpty = !multiple && files.length === 0

  const pollInterval =
    isPollingForDocuments || isFromDMS
      ? pollIntervalInput || DOCUMENT_POLLING_INTERVAL
      : 0

  const documentId = files[files.length - 1]?.id // always choose last

  const isQueryPaused =
    isFilesEmpty || (areAllDocumentsComplete && shouldPauseOnComplete)

  let tDocuments = useTransactionDocuments(transactionId, {
    enabled: !isQueryPaused && multiple,
    refetchInterval: pollInterval,
  })

  let tDocument = useDocument(documentId || "not-found", {
    enabled: Boolean(documentId) && !isQueryPaused && !multiple,
    refetchInterval: pollInterval,
  })

  let data: TransactionDocument | TransactionDocument[] | undefined = multiple
    ? tDocuments.data
    : tDocument.data
      ? tDocument.data
      : undefined

  useEffect(() => {
    setIsPollingForDocuments(hasInProgressUploads)
  }, [hasInProgressUploads])

  useEffect(() => {
    if (data) {
      dispatch({
        type: "UPDATE_DOCUMENTS",
        documents: Array.isArray(data) ? data : [data],
      })
    }
  }, [data])

  useEffect(() => {
    if (
      !multiple &&
      !Array.isArray(data) &&
      data?.status === "CONVERSION_COMPLETE"
    ) {
      onComplete(data)
    }
  }, [data, multiple])

  // triggerQueuedUpload
  function startUpload(file: FileUpload) {
    if (inQueue === false) {
      // Explicitly set to false, as 'true' is default
      file.inQueue = false
    }
    if (isFromDMS && !multiple) {
      triggerDMSWatch(file, dispatch)
      return
    }

    triggerUploadOperations(file, dispatch, transactionId)
  }

  function clearUploads() {
    dispatch({ type: "CLEAR" })
  }

  function clearUploadById(id: string) {
    dispatch({ type: "REMOVE_DOCUMENTS", documentIds: [id] })
  }

  return [files, startUpload, clearUploads, clearUploadById]
}
