import { useEffect, useState, ReactNode } from "react"
import {
  Card as DefaultCard,
  Checkbox,
  Tag,
  Icon,
  EditableText,
  Intent,
  Position,
  ProgressBar,
} from "@blueprintjs/core"
import { Tooltip2 } from "@blueprintjs/popover2"

import styled from "@emotion/styled"
import { motion, AnimatePresence } from "framer-motion"

import { useHasEverChangedTo } from "helpers/hooks"
import TimeIcon from "icons/array-timestamp.svg"

import {
  FileStatus,
  errorMsgForFailure,
} from "./DocumentUploadManager/operations"
import { useUploadPercentComplete } from "./hooks"
import { useUpdateDocument } from "./DocumentUploadManager/api"

const Card = styled(DefaultCard)`
  position: relative;
  padding: 0;
  font-size: 14px;
  color: #182026;
  letter-spacing: 0.78px;

  .bp4-control {
    margin: 0;
  }

  .bp4-tag {
    margin-right: 13px;
  }

  .bp4-progress-bar {
    height: 4px;
    border-radius: 0px;
    .bp4-progress-meter {
      border-radius: 3px;
    }
  }

  .name {
    min-width: 0;
    .bp4-editable-text {
      min-width: 0px;
    }

    .bp4-icon {
      visibility: hidden;
      margin-left: 8px;
    }

    &:hover {
      .bp4-icon {
        visibility: visible;
      }
    }
  }
`

type DocumentQueueCardProps = {
  children?: ReactNode
  id: string
  name: string
  numPages: number | null
  isSelected?: boolean
  onSelect?: (id: string) => void
  onUpdate?: (d: { id: string; name: string; status: FileStatus }) => void
  status: FileStatus
  signingType?: string
}

export default function DocumentQueueCard({
  children = null,
  id,
  name,
  numPages,
  isSelected = false,
  onSelect,
  onUpdate,
  status = "QUEUED",
  signingType = "",
}: DocumentQueueCardProps) {
  const [currentName, setCurrentName] = useState(name)
  const didConversionHappen = useHasEverChangedTo("CONVERSION_COMPLETE", status)
  const percentComplete = useUploadPercentComplete(status, numPages || 0)

  useEffect(() => {
    setCurrentName(name)
  }, [name])

  const isControlled = !!onUpdate

  const hasError = status.includes("FAILED")

  const isUploadInProgress = status !== "CONVERSION_COMPLETE" && !hasError

  const { mutate: editDocument } = useUpdateDocument()

  function handleSelect() {
    onSelect?.(id)
  }

  function submitNewName(newName: string) {
    if (isControlled) {
      if (newName.trim() !== name) {
        onUpdate({ id, name: newName, status })
      }
    } else {
      editDocument({ id, name: newName })
    }
  }

  return (
    <Card>
      <div className="flex p-2">
        <div className="flex min-w-0 flex-grow items-center">
          {onSelect && (
            <Checkbox large checked={isSelected} onChange={handleSelect} />
          )}
          {status === "WAITING_ON_DMS" && (
            <Tooltip2
              content={
                <div className="w-88">
                  After selecting a document from your Document Management
                  System, it may take a few moments for it to appear here.
                  Please do not close this window.
                </div>
              }
              position={Position.TOP}
            >
              <Icon
                className="mr-2"
                icon="error"
                intent={Intent.PRIMARY}
                size={20}
              />
            </Tooltip2>
          )}
          <div className="name flex">
            <EditableText
              className={status === "WAITING_ON_DMS" ? "text-gray-400" : ""}
              confirmOnEnterKey
              disabled={hasError || status === "WAITING_ON_DMS"}
              maxLength={350}
              value={
                status === "WAITING_ON_DMS"
                  ? "Accessing document...."
                  : currentName
              }
              onChange={setCurrentName}
              onConfirm={submitNewName}
            />
            {!hasError && !isUploadInProgress && (
              <Icon icon="edit" color="#5C7080" size={12} />
            )}
          </div>
        </div>
        <div className="ml-auto flex items-center">
          {status !== "WAITING_ON_DMS" && (
            <>
              {signingType === "non_executable" && (
                <Tag round minimal>
                  Non-executable
                </Tag>
              )}
              {/* Tag to display when document is executed */}
              {signingType === "fully_executed" && (
                <Tag intent={Intent.PRIMARY} round minimal>
                  Executed
                </Tag>
              )}
            </>
          )}
          {hasError && (
            <Tooltip2
              content={errorMsgForFailure(status)}
              position={Position.TOP}
            >
              <Icon icon="error" intent={Intent.DANGER} size={20} />
            </Tooltip2>
          )}
          <AnimatePresence>
            {didConversionHappen && !hasError && (
              <motion.div
                key="success-check"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: [0, 1, 1, 1, 1, 0],
                  scale: [0, 1.2, 1, 1, 1.2, 0],
                  transition: {
                    times: [0, 0.14, 0.17, 0.83, 0.86, 1],
                    duration: 2.2,
                  },
                }}
              >
                <Icon icon="tick-circle" intent={Intent.SUCCESS} size={20} />
              </motion.div>
            )}
          </AnimatePresence>
          {status === "QUEUED" && (
            <div className="color-light-gray-2 flex">
              <TimeIcon
                className="m-auto"
                height="20"
                width="20"
                viewBox="0 0 16 16"
              />
            </div>
          )}
        </div>
      </div>
      {children}
      {status && status !== "QUEUED" && status !== "CONVERSION_COMPLETE" && (
        <div className="absolute bottom-0 w-full">
          <ProgressBar intent={Intent.PRIMARY} value={percentComplete / 100} />
        </div>
      )}
    </Card>
  )
}
