import * as React from "react"
import {
  FileStatus,
  statusPercentComplete,
} from "./DocumentUploadManager/operations"

export const PER_PAGE_CONVERSION_TIME = process.env.PER_PAGE_CONVERSION_TIME
  ? Number(process.env.PER_PAGE_CONVERSION_TIME)
  : 400
export const INTERVAL = 1000

export function useUploadPercentComplete(status: FileStatus, numPages = 0) {
  let [percentComplete, setPercentComplete] = React.useState(
    statusPercentComplete(status)
  )
  let ticksRef = React.useRef<number | null>(null)
  let percentCompletePerTickRef = React.useRef<number | null>(null)
  let percentRemaining = 100 - statusPercentComplete(status)

  React.useEffect(() => {
    function recalculatePercentComplete() {
      if (
        ticksRef?.current &&
        percentCompletePerTickRef?.current &&
        ticksRef.current > 0
      ) {
        setPercentComplete(
          statusPercentComplete(status) +
            percentRemaining -
            ticksRef.current * percentCompletePerTickRef.current
        )
        ticksRef.current = ticksRef.current - 1
      }
    }

    if (status === "SUCCESS_CONVERTING_DOCUMENT" && !ticksRef.current) {
      if (ticksRef.current === 0) return // never complete

      ticksRef.current = Math.ceil(
        (numPages * PER_PAGE_CONVERSION_TIME) / INTERVAL
      )

      if (!percentCompletePerTickRef.current) {
        percentCompletePerTickRef.current = percentRemaining / ticksRef.current
      }

      let timer = setInterval(recalculatePercentComplete, INTERVAL)

      return () => clearInterval(timer)
    } else {
      setPercentComplete(statusPercentComplete(status))
    }
  }, [status])

  return percentComplete
}
