import { updateDocument } from "helpers/api"
import { useTransactionIdParam } from "helpers/params"
import { apiClient, useAPIMutation } from "lib/api"
import {
  encodeUpdatable as encodeUpdatableDocument,
  UpdatableDocument,
} from "models/Document"

export function useUpdateDocument(
  options: {
    onSuccess?: () => void
    onError?: () => void
  } = {}
) {
  let transactionId = useTransactionIdParam()

  return useAPIMutation(
    (doc: UpdatableDocument) => updateDocument(encodeUpdatableDocument(doc)),
    {
      onSuccess: () => {
        apiClient.refetchQueries([
          transactionId,
          "documents",
          { inQueue: true },
        ])
        options.onSuccess?.()
      },
      onError: () => {
        options.onError?.()
      },
    }
  )
}
