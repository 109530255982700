export type SortDirection = "asc" | "desc"
type SortType = "date" | "number" | "text"
type Comparable = any

const comparableFuncs = {
  date: {
    asc: (a?: Comparable, b?: Comparable) => {
      if (!!a && !!b) {
        return new Date(a).valueOf() - new Date(b).valueOf()
      }
      return 0
    },
    desc: (a?: Comparable, b?: Comparable) => {
      if (!!a && !!b) {
        return new Date(b).valueOf() - new Date(a).valueOf()
      }
      return 0
    },
  },
  number: {
    asc: (a?: Comparable, b?: Comparable) => Number(a || 0) - Number(b || 0),
    desc: (a?: Comparable, b?: Comparable) => Number(b || 0) - Number(a || 0),
  },
  text: {
    asc: (a?: Comparable, b?: Comparable) =>
      String(a).toUpperCase() < String(b).toUpperCase()
        ? -1
        : String(a).toUpperCase() > String(b).toUpperCase()
          ? 1
          : 0,
    desc: (a?: Comparable, b?: Comparable) =>
      String(a).toUpperCase() > String(b).toUpperCase()
        ? -1
        : String(a).toUpperCase() < String(b).toUpperCase()
          ? 1
          : 0,
  },
}

export function sortByKey(
  key: string,
  direction: SortDirection = "asc",
  type: SortType = "text"
) {
  return function (a: Record<string, any>, b: Record<string, any>) {
    return comparableFuncs[type][direction](a[key], b[key])
  }
}
