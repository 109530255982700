import { apiClient, useAPI } from "lib/api"

import { useTransactionIdParam } from "helpers/params"
import { getDocument } from "helpers/api"
import { type APIDocument, decode } from "models/Document"

export function useDocument(
  id: string,
  options?: { enabled?: boolean; refetchInterval?: number | false }
) {
  let transactionId = useTransactionIdParam()

  return useAPI(
    ["transactions", transactionId, "documents", id],
    () => getDocument(id),
    {
      initialData: () => {
        let maybeData = apiClient.getQueryData<APIDocument[]>(
          ["transactions", transactionId, "documents"],
          {
            exact: true,
          }
        )

        if (maybeData && Array.isArray(maybeData)) {
          return maybeData.find((d) => d.uuid === id)
        }
      },
      select: (d) => (d ? decode(d) : undefined),
      ...options,
    }
  )
}
