import styled from "@emotion/styled"
import { Colors } from "@blueprintjs/core"
import { FileRejection, useDropzone } from "react-dropzone"
import { ReactNode, useEffect, useState } from "react"

import { MINIMUM_FILE_SIZE } from "components/add-documents/DocumentsQueue/DocumentUploadManager/operations"

import useToaster from "helpers/toaster"
import { uniqByValue } from "helpers/array"

export const DroppableContainer = styled.div<{
  isDragging: boolean
  isDroppable: boolean
}>`
  z-index: 1;
  position: relative;

  &:after {
    content: "";
    display: ${(props) =>
      props.isDragging && props.isDroppable ? "block;" : "none;"};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    /* Colors.VIOLET4 at 70% transparency */
    background: rgba(189, 107, 189, 0.3);
    border-radius: 5px;
    margin: 0 auto;
    z-index: 2;
  }
`

export const DroppableMessage = styled.div<{ fixedToWindow?: boolean }>`
  display: flex;
  position: ${(props) => (props.fixedToWindow ? "fixed" : "absolute")};
  left: 50%;
  top: 24px;
  z-index: 10;
  background: ${Colors.VIOLET3};
  color: ${Colors.WHITE};
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  width: 403px;
  height: 71px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 500;

  .bp4-icon {
    margin-right: 22px;
  }
`

const errorMsgForCode: Record<string, string> = {
  "file-invalid-type":
    "The document you are attempting to upload is not a supported file type. Please try uploading your document again as a PDF.",
}

type DroppableSetFilesProps = {
  files?: File[]
  onFiles?: (files: File[]) => void
}

export function DroppableSetFiles({ files, onFiles }: DroppableSetFilesProps) {
  useEffect(() => {
    if (files && onFiles) {
      onFiles(files)
    }
  }, [files])

  return null
}

type DraggableContainerChildArgs = {
  clear: () => void
  isDragging: boolean
  isDroppable: boolean
  onOpen: () => void
  renderInput: () => ReactNode
  rootProps: Record<string, any>
  setIsDroppable: (isDroppable: boolean) => void
  files: File[]
}

type DraggableContainerProps = {
  children: (childArgs: DraggableContainerChildArgs) => ReactNode
  initialIsDroppable?: boolean
  accept?: Record<string, any>
}

export default function DraggableContainer({
  children,
  initialIsDroppable = false,
  accept = {
    "application/pdf": [], // any PDF file
    "application/msword": [], // just .doc
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      [], // just .docx
  },
}: DraggableContainerProps) {
  const [isDroppable, setIsDroppable] = useState(initialIsDroppable)
  const [acceptedFiles, setAcceptedFiles] = useState<File[]>([])

  useEffect(() => {
    if (!isDroppable) {
      setAcceptedFiles([]) // reset files if no longer can upload
    }
  }, [isDroppable])

  const { failure: toasterFailure } = useToaster()

  const onDropAccepted = (files: File[]) => {
    setAcceptedFiles([...acceptedFiles, ...files])
  }

  const onDropRejected = (rejectedFiles: FileRejection[]) => {
    if (rejectedFiles.length > 0) {
      const errorMsgs = uniqByValue(
        rejectedFiles.flatMap((r) =>
          r.errors.map((e) => errorMsgForCode[e.code] || e.message)
        )
      )
      toasterFailure(
        errorMsgs.map((error) => <div key={error}>{error}</div>),
        6000
      )
      return
    }
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDropAccepted,
    onDropRejected,
    accept,
    noClick: true,
    multiple: false,
    minSize: MINIMUM_FILE_SIZE,
  })

  return (
    <>
      {children({
        clear: () => setAcceptedFiles([]),
        isDragging: isDragActive,
        isDroppable,
        onOpen: open,
        renderInput() {
          return (
            <>
              <input {...getInputProps()} />
            </>
          )
        },
        rootProps: isDroppable ? getRootProps() : {},
        setIsDroppable,
        files: acceptedFiles,
      })}
    </>
  )
}
