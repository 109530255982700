import React, { useEffect } from "react"

import { useDocumentUpload } from "./"

import { useTransactionIdParam } from "helpers/params"

type DocumentUploadProps = {
  children?: React.ReactNode
  files?: any[]
  fieldName?: string
  onComplete?: () => void
  isAddingFromDMS?: boolean
}

export default function DocumentUpload({
  children,
  files,
  isAddingFromDMS,
  fieldName = "uploads",
}: DocumentUploadProps) {
  const transactionId = useTransactionIdParam()

  const [uploads, uploadFile, clearUploads] = useDocumentUpload({
    transactionId,
    inQueue: false,
    isFromDMS: isAddingFromDMS,
    multiple: false,
    shouldPauseOnComplete: true,
  })

  useEffect(() => {
    // NOTE this is `files` but will only ever be array of length 1
    files.forEach(uploadFile)
  }, [files])

  useEffect(() => () => clearUploads(), [])

  if (uploads.length > 0) {
    return React.cloneElement(React.Children.only(children), {
      [fieldName]: uploads[0],
    })
  }

  if (isAddingFromDMS) {
    return children
  }

  return null
}
